<!-- LOADING PROGRESS BAR -->
<ng-progress [min]="1"
             [spinner]="false"
             [color]="'#1B95E0'"></ng-progress>

<!-- NAVBAR -->
<app-navbar></app-navbar>

<!-- MAIN ROUTER OUTLET -->
<div [@routeAnimations]="prepareRoute(outlet)"
     (@routeAnimations.start)="animationStart()"
     (@routeAnimations.done)="animationDone()">
    <router-outlet #outlet="outlet">
    </router-outlet>
</div>

<!-- FOOTER -->
<div [@fadeInAnimation]
     *ngIf="footerVisible">
    <app-footer></app-footer>
</div>
