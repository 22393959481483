import { trigger, transition, style, query, animate, group, animateChild } from '@angular/animations';

export const routerTransition = trigger('routeAnimations', [
    transition('* <=> *', [
        style({ position: 'relative' }),
        query(':enter, :leave', [
            style({
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%'
            })
        ], { optional: true }),
        query(':enter', [
            // style({ left: '-100%', opacity: 0 })
            style({ opacity: 0 })
        ], { optional: true }),
        query(':leave', animateChild(), { optional: true }),
        group([
            query(':leave', [
                animate('500ms ease-in', style({ opacity: 0.6 }))
            ], { optional: true }),
            query(':enter', [
                animate('750ms ease-in', style({ opacity: 1 }))
            ], { optional: true })
        ]),
        query(':enter', animateChild(), { optional: true }),
    ])
]);

export const footerFadeIn = trigger(
    'fadeInAnimation', [
    transition(':enter', [
        style({ opacity: 0 }),
        animate('.3s', style({ opacity: 1 }))
    ]),
])
