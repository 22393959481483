<ngu-carousel
    #carousel
    [inputs]="carouselConfig"
    [dataSource]="carouselData">

    <div *nguCarouselDef="let item;" class="item">
        <div class="tile">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12 col-md-8 d-block pl-0 order-1 order-md-0">
                        <blockquote class="blockquote">
                            <i [innerHTML]="item.attributes.text">
                            </i>
                            <footer class="blockquote-footer">
                                {{ item.attributes.author }}
                            </footer>
                        </blockquote>
                    </div> <!-- col-12 col-md-8 ends here -->
                    <div class="col-12 col-md-4 d-block m-auto order-0 order-md-1 text-center">
                        <img *ngIf="item.attributes.img"
                             [src]="settings.url.api.root + item.attributes.img.data.attributes.url"
                             class="p-1 img-fluid mb-4 mb-md-0 rounded">
                    </div> <!-- col-12 col-md-4 ends here -->
                </div> <!-- row ends here -->
            </div> <!-- container-fluid ends here -->
        </div>
    </div>

    <!-- <i NguCarouselNext
         class="carouselCtrl carouselCtrlR fa fa-2x fa-chevron-right">
         </i>
         <i NguCarouselPrev
         class="carouselCtrl carouselCtrlL fa fa-2x fa-chevron-left">
         </i> -->
</ngu-carousel>
