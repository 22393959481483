<div class="container-fluid bg-primary-gradient attention-banner">
    <div class="row text-center">
        <div class="col-12 col-sm-8 m-auto pb-4 pb-sm-0">
            <h4 class="m-0 d-block text-white">
                {{ text }}
            </h4>
        </div> <!-- col-12 col-sm-8 ends here -->
        <div class="col-12 col-sm-4 m-auto ">
            <a [routerLink]="link" class="btn btn-lg btn-white" *ngIf="!noRouting; else externalLink">
                {{ buttonText }}
            </a>
            <ng-template #externalLink>
                <a [href]="link" class="btn btn-lg btn-white" target="_blank">
                    {{ buttonText }}
                </a>
            </ng-template>
        </div> <!-- col-12 col-sm-4 ends here -->
    </div> <!-- row ends here -->
</div> <!-- container-fluid ends here -->
