import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NguCarouselConfig, NguCarousel } from '@ngu/carousel';
import { CarouselData } from 'src/app/shared/interfaces/carousel-data.interface';
import { settings } from 'src/app/shared/settings/settings';
import { MarkdownService } from 'ngx-markdown';

@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
    settings = settings;
    @Input('carouselData') carouselData: CarouselData[];

    public carouselConfig: NguCarouselConfig;
    @ViewChild('carousel', { static: false }) carousel: NguCarousel<any>;

    constructor(
        private readonly md: MarkdownService,
    ) { }

    ngOnInit(): void {
        // setup carousel config
        this.carouselConfig = {
            grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
            load: 3,
            interval: { timing: 10000, initialDelay: 1000 },
            loop: true,
            touch: true,
            velocity: 0.2
        };

        this.compileMarkdown();
    }

    /**
     * Compile markdown on carousel data
     */
    compileMarkdown() {
        if (Array.isArray(this.carouselData) && this.carouselData.length != 0) {
            // compile markdown
            this.carouselData.forEach((item) => {
                item.attributes.text = this.md.compile(item.attributes.text);
            })
        }
    }
}
