<!-- footer -->
<div class="container-fluid footer-main" id="footer">
    <footer>
        <!-- CONTACTS -->
        <div class="row text-center row-extra-margin-bottom mt-5">
            <div class="col-md-4 col-12 py-3">
                <p class="text-half-big"><b>COMAND Technology Gateway Manager</b></p>
                <p>
                    Anthony Cunningham <br>
                    <i class="fa fa-phone"></i>
                    <a href="tel:+353906483096" class=""> +353 (0) 90 6483096</a>
                    <br>
                    <i class="fa fa-envelope"></i>
                    <a href="mailto:anthony.cunningham@tus.ie" class=""> anthony.cunningham@tus.ie</a>
                </p>
            </div>
            <div class="col-md-4 col-12 py-3">
                <p class="text-half-big"><b>Director of the Software Research Institute</b></p>
                <p>
                    Dr. Brian Lee <br>
                    <i class="fa fa-phone"></i>
                    <a href="tel:+353906483035" class=""> +353 (0) 90 6483035</a>
                    <br>
                    <i class="fa fa-envelope"></i>
                    <a href="mailto:brian.lee@tus.ie" class=""> brian.lee@tus.ie</a>
                </p>
            </div>
            <div class="col-md-4 col-12 py-3">
                <p class="text-half-big"><b>Technological University of the Shannon</b></p>
                <p>
                    Athlone Campus, Dublin Road, Athlone<br>
                    <i class="fa fa-phone"></i>
                    <a href="tel:+353906468000" class=""> +353 (0) 90 6483035</a>
                    <br>
                    <i class="fa fa-envelope"></i>
                    <a href="mailto:reception.midlands@tus.ie" class=""> reception.midlands@tus.ie</a>
                </p>
            </div>
        </div> <!-- row ends here -->

        <!-- EXTERNAL LINKS -->
        <div class="row my-5 text-center footer-ext-links">
            <div class="footer-link m-auto col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                <a href="https://www.enterprise-ireland.com/en/" target="_blank">
                    <img src="assets/images/ei_logo.png" alt="Enterprise Ireland" />
                </a>
            </div>
            <div class="footer-link m-auto col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                <a href="https://sri-tus.eu/" target="_blank">
                    <img src="assets/images/SRI_logo.png" alt="SRI" />
                </a>
            </div>
            <div class="footer-link m-auto col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                <a href="https://comand.ie/">
                    <img src="assets/images/comand-logo-web-size-white.jpg" alt="COMAND" />
                </a>
            </div>
            <div class="footer-link m-auto col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                <a href="https://hea.ie/" target="_blank">
                    <img class="rounded" src="assets/images/intergrated_development.png" alt="Integrated Development" />
                </a>
            </div>
            <div class="footer-link m-auto col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                <a href="https://eufunds.ie/" target="_blank" >
                    <img class="rounded" src="assets/images/european_union_ESF.png" alt="European Union" />
                </a>
            </div>

        </div>

        <div class="row text-center footer-bottom">
            <div class="col-6">
                <a routerLink="/privacypolicy" class="text-white">Privacy Policy</a>
            </div>
            <div class="col-6">
                <a routerLink="/terms" class="text-white">Terms &amp; Conditions</a>
            </div><!-- col-6 ends here -->
            <div class="col-12">
                <p class="text-half-big">Copyright &copy; COMAND {{year}} All rights reserved</p>
            </div>
        </div> <!-- row -->
        <div class="row text-center footer-bottom">
            <div class="col-12">
                <p class="text-half-big">Designed By <a href="https://comand.ie" class="text-info">COMAND</a></p>
            </div>
        </div> <!-- row -->
    </footer>
</div>
