const url = "https://comand.ie";
const apiUrl = "https://cms.comand.ie/api";
const rootUrl = "http://cms.comand.ie";

// const url = "http://localhost:4200";
// const apiUrl = "http://localhost:1337/api";
// const rootUrl = "http://localhost:1337";

export const settings = {
    url: {
        root: url,
        api: {
            root: rootUrl,
            testimonials: apiUrl + "/comand-testimonials",
            news: apiUrl + "/comand-news",
            projects: apiUrl + "/comand-projects",
            researches: apiUrl + "/comand-researches",
            services: apiUrl + "/comand-services",
            funding: apiUrl + "/comand-fundings",
            goals: apiUrl + "/comand-goals",
            technologies: apiUrl + "/comand-technologies",
            contact: apiUrl + "/comand-contacts",
        }
    }
};
