import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-attention',
    templateUrl: './attention.component.html',
    styleUrls: ['./attention.component.scss']
})
export class AttentionComponent implements OnInit {
    @Input('noRouting') noRouting: boolean;
    @Input('text') text: string;
    @Input('link') link: string;
    @Input('button-text') buttonText: string;


    constructor() { }

    ngOnInit(): void {
        if (this.noRouting === undefined) {
            this.noRouting = false;
        }
    }

}
