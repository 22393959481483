
/**
 * Scroll to the top of the page
 */
export function scrollToTop() {
    // help firefox deal with the scroll by putting a timeout
    setTimeout(() => {
        try {
            window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        } catch (e) {
            window.scrollTo(0, 0);
        }
    }, 50);
}
