import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { footerFadeIn, routerTransition } from 'src/app/shared/animations/router-transitions.animations';
import { scrollToTop } from 'src/app/shared/functions/helpers.functions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        routerTransition,
        footerFadeIn,
    ]
})
export class AppComponent {
    footerVisible = false;

    /**
     * Check if there's a need to trigger route animation
     * @param outlet Router state
     */
    prepareRoute(outlet: RouterOutlet) {
        return outlet && outlet.activatedRouteData && outlet.activatedRouteData['state'];
    }

    /**
     * On animation start
     */
    animationStart() {
        this.footerVisible = false;

        scrollToTop();
    }

    /**
     * On animation finished
     */
    animationDone() {
        this.footerVisible = true;
    }
}
