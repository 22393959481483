import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NguCarouselModule } from '@ngu/carousel';

import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { AttentionComponent } from './attention/attention.component';
import { RouterModule } from '@angular/router';
import { CarouselComponent } from './carousel/carousel.component';
import { ContentSpinnerComponent } from './content-spinner/content-spinner.component';


@NgModule({
    declarations: [
        NavbarComponent,
        FooterComponent,
        AttentionComponent,
        CarouselComponent,
        ContentSpinnerComponent,
    ],
    exports: [
        NavbarComponent,
        FooterComponent,
        AttentionComponent,
        CarouselComponent,
        ContentSpinnerComponent,
    ],
    imports: [
        CommonModule,
        RouterModule,
        NguCarouselModule,
    ]
})
export class ComponentsModule { }
